import { memo } from 'react';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Hooks
import { useJobTimeZone } from '@/hooks';

// Helpers
import imageScaling from '@/utils/imageScaling';
import { successToast } from '@/utils/toast';
import { longDateTime } from '@/utils/displayFormats';

// Components
import PhotoCard from '../PhotoCard';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;

const AllPhotosGrid = memo(function AllPhotosGrid({
  job,
  photos,
  fields,
  requesting,
  pagination,
  filterTags,
  galleryType,
  biometricsEnabled,
  qrEnabled,
  nextSubject,
  filterPhotos,
  filterPeople,
  photoSelected,
  unselectedPhotos,
  allPhotosSelected,
  tagManagerOpenList,
  shiftHoverSelected,
  isGalleryGridDisabled,
  peopleManagerOpenList,

  onMouseEnter,
  onPhotoSelect,
  onPhotoFeature,
  onPhotoDragEnd,
  onAddEditToggle,
  onTagManagerShow,
  onInfiniteScroll,
  onPhotoDragStart,
  onDigitalDownload,
  onShowMatchToggle,
  onNavigateSubjects,
  onPhotoSelectClear,
  onPeopleManagerShow,
  onPhotosPeopleFilter,
  onShowLightboxToggle
}) {
  const [jobTimezone] = useJobTimeZone();
  const isSubjectDetail = filterPhotos === 'subjects' && filterPeople?.id;

  // Are there more items to load?
  const hasNextPage = photos.length < pagination.total;

  // Inject the subject reference photo if a subject is selected
  const galleryPhotoList = filterPeople.id
    ? [{ id: filterPeople.id, image_filename: 'Reference Photo', image_url: filterPeople.session_photo_url, reference_photo: true }, ...photos]
    : photos;

  const isPrivateJob = job.access_mode === 'access_per_subject';
  const accessCode = isPrivateJob ? filterPeople.access_id : job.access_code;
  const galleryUrl = `${MY_PHOTODAY_URL}g/${accessCode}`;

  const handleGalleryLinkCopy = () => {
    navigator.clipboard.writeText(galleryUrl);
    successToast('The gallery link has been successfully copied!');
  };

  return (
    <section className={`job-photo-grid ${isGalleryGridDisabled ? 'disabled' : ''}`}>
      {isSubjectDetail && (
        <>
          <div className="button-group flex middle between animate job-photo-grid__subject hidden--md">
            <div className="flex middle start">
              <div className="job-photo-grid__subject__image-container">
                <LazyLoadImage
                  className="job-photo-grid__subject__image"
                  src={filterPeople.session_photo_url ? imageScaling({ url: filterPeople.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
                  alt={filterPeople.first_name}
                  height={40}
                  draggable="false"
                />
              </div>

              <h2 className="text--nomargin text--capitalize">
                {`${filterPeople.first_name} ${filterPeople.last_name} `}
                {isPrivateJob && (
                  <Tooltip title="Copy subject's gallery link" position="top" arrow={false} delay={300}>
                    <button className="button button--link ml-5" name="copy" onClick={handleGalleryLinkCopy}>
                      <i className="icon-link"></i>
                    </button>
                  </Tooltip>
                )}
              </h2>
            </div>

            <div className="flex middle end button-group">
              {(nextSubject.first_name || nextSubject.last_name) && (
                <span className="flex column text--grey">
                  <small>Loading:</small>
                  <small className="animate"> {`${nextSubject.first_name} ${nextSubject.last_name}`}</small>
                </span>
              )}

              <Tooltip title="Previous Subject" position="top" arrow={false} delay={300}>
                <button
                  className="button button--outline button--small"
                  name="arrow-up"
                  type="button"
                  onClick={() => {
                    onNavigateSubjects(true, null);
                  }}
                >
                  <i className="icon-arrow-up--dark"></i>
                </button>
              </Tooltip>
              <Tooltip title="Next Subject" position="top" arrow={false} delay={300}>
                <button
                  className="button button--outline button--small"
                  name="arrow-down"
                  type="button"
                  onClick={() => {
                    onNavigateSubjects(null, true);
                  }}
                >
                  <i className="icon-arrow-down--dark"></i>
                </button>
              </Tooltip>
            </div>
          </div>
          <aside className="flex nowrap middle job-photo-grid__subject-details">
            {Object.keys(fields).length > 0 ? (
              <dl className="flex start nowrap job-photo-grid__subject-data">
                {Object.keys(fields)
                  .filter((field) => field !== 'first_name' && field !== 'last_name' && field !== 'added_by' && field !== 'absent')
                  .filter((field) => filterPeople[field])
                  .map((field, index) => (
                    <div className={`flex column ${index !== 0 ? 'hidden--sm' : ''}`} key={index}>
                      <dt className="text--bold" key={field}>
                        {field.replace('session_start', 'check-in_date').replace('_', ' ')}
                      </dt>
                      <dd className={`${field.includes('email') ? 'text--lowercase' : ''}`} draggable={false}>
                        {field.includes('session_start')
                          ? moment(filterPeople[field])
                              .tz(jobTimezone ?? '')
                              .format('L [@]h:mm A')
                          : filterPeople[field]}
                      </dd>
                    </div>
                  ))}
              </dl>
            ) : (
              <dl className="flex start nowrap job-photo-grid__subject-data">
                <div className="flex column">
                  <dt className="text--bold">Access Code</dt>
                  <dd>{filterPeople.access_id ? filterPeople.access_id : '-----'}</dd>
                </div>
              </dl>
            )}
            <button className="button button--outline" onClick={onAddEditToggle}>
              Edit
            </button>
          </aside>
        </>
      )}
      {galleryPhotoList.length ? (
        <InfiniteScroll
          className="job-photo-grid__row"
          height={`calc(100vh - ${isSubjectDetail ? 450 : 300}px)`}
          scrollThreshold={0.7}
          dataLength={galleryPhotoList.length}
          next={!requesting && onInfiniteScroll}
          hasMore={hasNextPage}
        >
          {galleryPhotoList.map((photo) => (
            <div
              key={photo.id}
              className={`job-photo-grid__item ${photoSelected.includes(photo.id) ? 'job-photo-grid__item--active' : ''} ${
                shiftHoverSelected.includes(photo.id) ? 'job-photo-grid__item--shift-select' : ''
              }`}
              onMouseEnter={(e) => photoSelected.length && onMouseEnter(e, photo.id)}
            >
              <PhotoCard
                photo={photo}
                person={filterPeople.id ? filterPeople : null}
                requesting={requesting}
                filterTags={filterTags}
                galleryType={galleryType}
                biometricsEnabled={biometricsEnabled}
                qrEnabled={qrEnabled}
                filterPeople={filterPeople}
                filterPhotos={filterPhotos}
                photoSelected={photoSelected}
                onPhotoSelect={onPhotoSelect}
                isReferencePhoto={photo.reference_photo}
                unselectedPhotos={unselectedPhotos}
                allPhotosSelected={allPhotosSelected}
                tagManagerOpenList={tagManagerOpenList}
                peopleManagerOpenList={peopleManagerOpenList}
                onPhotoFeature={onPhotoFeature}
                onPhotoDragEnd={onPhotoDragEnd}
                onTagManagerShow={onTagManagerShow}
                onPhotoSelectClear={onPhotoSelectClear}
                onPhotoDragStart={onPhotoDragStart}
                onDigitalDownload={onDigitalDownload}
                onShowMatchToggle={onShowMatchToggle}
                onPeopleManagerShow={onPeopleManagerShow}
                onPhotosPeopleFilter={onPhotosPeopleFilter}
                onShowLightboxToggle={onShowLightboxToggle}
              />
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <>
          {requesting ? (
            <GridLoader rows={3} columns={5} gap={20} minHeight={302} />
          ) : (
            <aside className="flex middle center panel panel--secondary panel--tall animate">
              <h3 className="text--nomargin">No photos were found.</h3>
            </aside>
          )}
        </>
      )}
    </section>
  );
});

export default AllPhotosGrid;
